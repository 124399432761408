<template>
  <div>
    <div id="stripe-checkout"></div>
  </div>
</template>

<script>
// @ is an alias to /src
import LOBBY_ICONS from "@/utils/lobby_icons";
import { mapActions, mapState, mapGetters } from "vuex";

export default {
  name: "modulesorder",
  computed: {
    ...mapState({
      username: state => state.userProfile.userfullname,
      email: state => state.userProfile.email
    }),
    ...mapGetters({ subscriptions: "ACTIVE_SUBSCRIPTIONS" }),
    showActionButtons() {
      return this.order && !this.order.isPaid && this.stripe.loaded;
    },
    isConnectedOnline() {
      return this.$store.getters.IS_APP_CONNECTED_ONLINE;
    }
  },
  data() {
    return {
      order: {},
      stripe: {
        loaded: false,
        handler: null,
        element: null
      }
    };
  },
  methods: {
    ...mapActions("api", ["getOrder", "processOrder"]),
    ...mapActions(["selectSubscription"]),
    getImageData: function(name) {
      if (!name) return "";
      const language = this.$store.getters.LANGUAGE;
      return LOBBY_ICONS[name.format(language)];
    },
    onlinePopupError() {
      this.$store.dispatch(
        "alert/error",
        this.$store.getters.GET_CURRENT_ONLINE_ERROR
      );
    },
    initStripe() {
      const vuejs = this;
      vuejs.stripe.loaded = true;

      vuejs.stripe.handler = Stripe(vuejs.order.stripeKey, {
        apiVersion: "2025-01-27.acacia"
      });

      const fetchClientSecret = async () =>
        Promise.resolve(vuejs.order.clientSecret);
      const onComplete = async () => await vuejs.onStripeDone();
      vuejs.stripe.handler
        .initEmbeddedCheckout({
          fetchClientSecret,
          onComplete
        })
        .then(checkout => {
          checkout.mount("#stripe-checkout");
          vuejs.stripe.element = checkout;
        });
    },
    destroyStripe() {
      if (this.stripe.loaded) {
        this.stripe.element.destroy();
        this.stripe.element = null;
        this.stripe.handler = null;
        this.stripe.loaded = false;
      }
    },
    async onStripeDone() {
      const vuejs = this;
      const moduleId = this.$route.params.id;
      this.destroyStripe();
      return vuejs.$store
        .dispatch("showLoadingOverlay")
        .then(() => vuejs.processOrder({ module: moduleId }))
        .then(() => vuejs.onPurchaseCompleted())
        .catch(function(error) {
          console.error(error); // eslint-disable-line no-console
          if (error.errorType == "stripe") {
            this.$swal({
              title: this.$t("error"),
              text: this.$t("stripe.session"),
              icon: "error",
              button: "OK"
            });
            vuejs.$router.replace({ name: "receipts" });
          } else {
            vuejs.$alertUserApiError(error);
            vuejs.$router.replace({ name: "receipts" });
          }
        })
        .finally(() => vuejs.$store.dispatch("hideLoadingOverlay"));
    },
    onPurchaseCompleted() {
      // redirect to renewed subscription trip list.
      const vuejs = this;
      const moduleId = this.$route.params.id;
      let futureDate = this.order.expiration;
      return vuejs.$store
        .dispatch("updateSubscriptions")
        .then(() => {
          for (const subscription of this.subscriptions) {
            if (subscription.module.id == moduleId) {
              const uuid = subscription.uuid;
              futureDate = subscription.expiration;
              return vuejs.selectSubscription(uuid).then(() =>
                vuejs.$router.replace({
                  name: "module",
                  params: { uuid, expirationDate: futureDate }
                })
              );
            }
          }
          return vuejs.$router.replace({
            name: "modules",
            params: { expirationDate: futureDate }
          });
        })
        .catch(() => vuejs.$router.replace({ name: "modules" }));
    },
    cancel() {
      this.$router.replace({ name: "modules" });
    }
  },
  beforeDestroy() {
    this.destroyStripe();
  },
  mounted() {
    var vuejs = this;
    if (this.$route.params && this.$route.params.id) {
      vuejs
        .getOrder(vuejs.$route.params.id)
        .then(function(order) {
          vuejs.order = order;
          if (order.isPaid) {
            return vuejs.onPurchaseCompleted();
          } else {
            // pris de vue-plugin-load-script
            let el = document.querySelector(
              'script[src="https://js.stripe.com/v3/"]'
            );
            if (!el) {
              el = document.createElement("script");
              el.type = "text/javascript";
              el.async = true;
              el.src = "https://js.stripe.com/v3/";
              el.onload = function() {
                vuejs.initStripe();
              };
              document.head.appendChild(el);
            } else if (vuejs.order) {
              vuejs.initStripe();
            }
          }
        })
        .catch(error => vuejs.$alertUserApiError(error))
        .finally(() => vuejs.$store.dispatch("hideLoadingOverlay"));
    }
  }
};
</script>

<style lang="scss" scoped>
#stripe-checkout {
  padding-bottom: 10px;
  background-color: white;
}
</style>
